
.health-section .tl-contr h1[data-v-89a78ef2] {
    margin-bottom: 40px;
}
.health-section .hs-fea[data-v-89a78ef2] {
    padding-top: 50px;
    justify-content: space-between;
}
.health-section .hs-fea .cn-center[data-v-89a78ef2] {
    float: left;
    border: 1px solid #E6771B;
    box-sizing: border-box;
    border-radius: 10px;
    width: 31.333%;
    min-height: 90px;
    position: relative;
    text-align: left;
}
.health-section .hs-fea .cn-center .v-center[data-v-89a78ef2] {
    padding-left: 60px;
    padding-right: 10px;
}
.health-section .hs-fea .cn-center .v-center p[data-v-89a78ef2] {
    font-size: 14px;
}
.health-section .hs-fea .cn-center .v-center img[data-v-89a78ef2] {
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 30px;
}
.health-section .ns-contr[data-v-89a78ef2] {
    background: #F1F3F5;
    padding-top: 40px;
    padding-bottom: 40px;
}
.health-section .ns-contr .health[data-v-89a78ef2] {
    width: 25%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.health img[data-v-89a78ef2] {
    width: 100%;
}
.tl-contr h1[data-v-89a78ef2] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-89a78ef2]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.to-top[data-v-89a78ef2] {
    padding-top: 50px;
}
.to-top h1[data-v-89a78ef2]::before {
    margin: auto;
    left: 0;
    right: 0;
}
.hs-fea h1[data-v-89a78ef2] {
    font-size: 28px;
}
@media all and (max-width: 1440px) {
.to-top[data-v-89a78ef2] {
        padding-top: 70px;
}
.health-section .hs-fea[data-v-89a78ef2] {
        padding-top: 70px;
}
}
@media all and (max-width: 991px) {
.health-section .hs-fea .cn-center[data-v-89a78ef2] {
        width: 49%;
        height: 130px;
        margin-bottom: 15px;
}
.health-section .hs-fea[data-v-89a78ef2]{
        padding-top: 50px;
}
.to-top[data-v-89a78ef2] {
        padding-top: 50px;
}
}
@media all and (max-width: 767px) {
.hs-fea h1[data-v-89a78ef2]{
        font-size: 24px;
}
.health-section .ns-contr .health[data-v-89a78ef2] {
        width: 33.333%;
}
}
@media all and (max-width: 599px) {
.health-section .hs-fea .cn-center[data-v-89a78ef2]{
        width: 100%;
}
.health-section .ns-contr[data-v-89a78ef2] {
        padding-top: 30px;
        padding-bottom: 30px;
}
}
@media all and (max-width: 480px) {
.health-section .ns-contr .health[data-v-89a78ef2] {
        width: 50%;
}
}