
.tl-contr.to-top[data-v-b20658d1] {
    margin-top: 50px;
}
.tl-contr.to-top h1[data-v-b20658d1]::before {
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom: 20px;
}
.hospitals-contr[data-v-b20658d1] {
    padding-top: 50px;
}
.hospitals-contr .tl-contr ul.country-contr[data-v-b20658d1] {
    float: left;
    width: 100%;
    margin-bottom: 5px;
    position: relative;
}
.hospitals-contr .tl-contr ul.country-contr li[data-v-b20658d1] {
    float: left;
    padding: 0px 25px;
    padding-bottom: 15px;
    text-overflow: clip;
    position: relative;
}
.hospitals-contr .tl-contr ul.country-contr[data-v-b20658d1]:after {
    border-bottom: 6px solid #F1F3F5;
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0px;
}
.hospitals-contr .tl-contr ul.country-contr li.active[data-v-b20658d1]:after {
    border-bottom: 6px solid #E6771B;
    content: "";
    position: absolute;
    bottom: -5px;
    width: 100%;
    left: 0px;
    z-index: 1;
}
.hospitals-contr img[data-v-b20658d1] {
    object-fit: cover;
}
.hospitals-contr .tl-contr .radio-btn-contr[data-v-b20658d1] {
    margin-bottom: 15px;
}
.hospitals-contr .tl-contr .radio-btn-contr h1[data-v-b20658d1] {
    margin-bottom: 15px;
}
.hospitals-contr .tl-contr .radio-btn-contr .radio-btn[data-v-b20658d1] {
    position: relative;
    /* padding: 5px 10px; */
    padding-left: 28px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    float: left;
    margin-right: 10px;
    margin-top: 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    border-radius: 20px;
    height: 22px;
}
.hospitals-contr .tl-contr .radio-btn-contr .radio-btn input[data-v-b20658d1] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.hospitals-contr .tl-contr .radio-btn-contr .radio-btn .checkmark[data-v-b20658d1] {
    position: absolute;
    top: 13px;
    left: 10px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid #213B4D;
}
.hospitals-contr .tl-contr .radio-btn-contr .radio-btn .checkmark[data-v-b20658d1]:after {
    content: "";
    position: absolute;
    display: none;
}
.hospitals-contr .tl-contr .radio-btn-contr .radio-btn input:checked~.checkmark[data-v-b20658d1]:after {
    display: block;
}
.hospitals-contr .tl-contr .radio-btn-contr .radio-btn .checkmark[data-v-b20658d1]:after {
    top: -2px;
    left: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #E6771B;
}
.hospitals-contr .tl-contr .radio-btn-contr .radio-btn.active[data-v-b20658d1] {
    background: #E7E7E7;
}
.hospitals-contr .item[data-v-b20658d1] {
    background: #F1F3F5;
    text-align: left;
    padding: 0px 30px 20px 30px;
    position: relative;
}
.hospitals-contr .item h2[data-v-b20658d1] {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 52px;
    margin: 15px 0px;
    font-family: TitilliumWeb-Regular;
}
.hospitals-contr .item .content[data-v-b20658d1] {
    display: inline-flex;
    width: 100%;
    border-bottom: 1px solid #213B4D;
    padding-bottom: 20px;
    margin-bottom: 20px;
    height: 45px;
}
.hospitals-contr .item .content img[data-v-b20658d1] {
    width: auto !important;
    margin-right: 10px;
    float: left;
    margin-top: 3px;
}
.hospitals-contr .item .content p[data-v-b20658d1]{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.hospitals-contr .item a.explore[data-v-b20658d1] {
    color: #213B4D;
    display: flex;
}
.hospitals-contr .item a.explore img[data-v-b20658d1] {
    width: 10px;
    margin-left: 10px;
    object-fit: contain;
    margin-top: 5px;
}
.hospitals-contr .item .book-now[data-v-b20658d1] {
    background: #E6771B;
    border-radius: 10px 0px 0px 10px;
    border: none;
    color: #fff;
    padding: 8px 20px;
    position: absolute;
    right: 0;
    top: 25px;
    font-family: WorkSans-Medium;
    font-size: 16px;
}
.testimonial[data-v-b20658d1]{
    margin: 0px -30px;
}
@media all and (max-width: 1440px) {
.hospitals-contr[data-v-b20658d1] {
        padding-top: 70px;
        padding-bottom: 0px;
}
.hospitals-contr .item h2[data-v-b20658d1]{
        height: 45px;
}
.hospitals-contr .item .book-now[data-v-b20658d1] {
        padding: 6px 15px;
}
.hospitals-contr .tl-contr ul.country-contr li.active[data-v-b20658d1]:after {
        bottom: -2px;
}
}
@media all and (max-width: 1199px) {
.hospitals-contr .item[data-v-b20658d1]{
        padding: 0px 20px 20px 20px;
}
}
@media all and (max-width: 991px) {
.tl-contr.to-top[data-v-b20658d1] {
        margin-top: 50px;
}
.hospitals-contr .tl-contr[data-v-b20658d1] {
        margin-bottom: 0;
}
.hospitals-contr[data-v-b20658d1]{
        padding-top: 50px;
}
}
