.banner-bg[data-v-d9c93b5d] {
    height: 360px;
    object-fit: cover;
    width: 100%;
}
.banner-bottom h1[data-v-d9c93b5d] {
    background: #E6771B;
    box-shadow: 0px 11px 10px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    color: #fff;
    max-width: 430px;
    padding: 10px 80px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    font-size: 21px;
    padding-right: 0;
}
.banner-bottom h1 img[data-v-d9c93b5d] {
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 35px;
}
.banner-bottom h1 .arrow-right[data-v-d9c93b5d] {
    right: 20px;
    left: auto;
    width: 15px;
}








/*doctor search open*/
.doctors[data-v-d9c93b5d]{
    background: #F1F3F5;
    padding: 20px;
    padding-top: 50px;
    border-radius: 10px;
    margin-top: 10px !important;
}
.doctors-list[data-v-d9c93b5d] {
    position: relative;
    padding-left: 115px;
    min-height: 170px;
    margin-bottom: 25px;
    border-bottom: 1px solid #333333;
}
.doctor-img[data-v-d9c93b5d] {
    position: absolute;
    left: 0px;
    width: 90px;
}
.doctor-img img[data-v-d9c93b5d]{
    max-width: 100%;
    position: static !important;
    position: initial !important;
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 100%;
    background-color: #fff;
}
.btcn[data-v-d9c93b5d] {
    background: #00AAC4;
    border-radius: 5px;
    color: #fff;
    height: 40px;
    width: 170px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.btcn1[data-v-d9c93b5d] {
    background: #E6771B;
    border-radius: 5px;
    color: #ffffff;
    height: 40px;
    width: 170px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 15px;
}
.btcn[data-v-d9c93b5d]:hover{
    background-color: #07899d;
    color: #fff;
}
.btcn1[data-v-d9c93b5d]:hover{
    background-color: #cf660f;
    color: #ffffff;
}
.tip-list img[data-v-d9c93b5d] {
    position: static !important;
    position: initial !important;
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.tip-list[data-v-d9c93b5d] {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}
.tip-list p[data-v-d9c93b5d] {
    font-size: 21px;
    margin-top: 10px;
    text-align: left;
    margin-bottom: 10px;
}
.t1[data-v-d9c93b5d] {
    color: #E6771B;
    font-size: 14px;
    float: left;
}
.tip-tl[data-v-d9c93b5d]{
    color: #058ED2;
    text-align: left;
    margin-bottom: 15px;
}
.doctor-left[data-v-d9c93b5d]{
    padding-right: 50px;
    padding-left: 20px;
    border-right: 1px solid #C4C4C4;
    width: 73%;
}
.doctor-right[data-v-d9c93b5d] {
    padding-left: 50px;
    width: 27%;
}
.doctors-list[data-v-d9c93b5d]:last-child {
    border: none;
}
.ds-detail[data-v-d9c93b5d] {
    float: left;
    text-align: left;
}
/*doctor search close*/



/*Hospital search open*/
.hospital[data-v-d9c93b5d]{
    background: #F1F3F5;
    padding: 20px;
    padding-top: 50px;
    border-radius: 10px;
    margin-top: 10px !important;
}
.hospital-list[data-v-d9c93b5d] {
    position: relative;
    min-height: 220px;
    margin-bottom: 25px;
    padding-left: 350px;
}
.hospital-img[data-v-d9c93b5d] {
    position: absolute;
    left: 0;
    width: 320px;
    height: 220px;
}
.hospital-img img[data-v-d9c93b5d]{
    max-width: 100%;
    position: static !important;
    position: initial !important;
    width: 320px;
    height: 200px;
    object-fit: cover;
    background-color: #fff;
}
.btcn[data-v-d9c93b5d] {
    background: #00AAC4;
    border-radius: 5px;
    color: #fff;
    height: 40px;
    width: 170px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.btcn1[data-v-d9c93b5d] {
    background: #E6771B;
    border-radius: 5px;
    color: #ffffff;
    height: 40px;
    width: 170px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 15px;
}
.btcn[data-v-d9c93b5d]:hover{
    background-color: #07899d;
    color: #fff;
}
.btcn1[data-v-d9c93b5d]:hover{
    background-color: #cf660f;
    color: #ffffff;
}
.tip-list img[data-v-d9c93b5d] {
    position: static !important;
    position: initial !important;
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.tip-list[data-v-d9c93b5d] {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}
.tip-list p[data-v-d9c93b5d] {
    font-size: 21px;
    margin-top: 10px;
    text-align: left;
    margin-bottom: 10px;
}
.t1[data-v-d9c93b5d] {
    color: #E6771B;
    font-size: 14px;
    float: left;
}
.tip-tl[data-v-d9c93b5d]{
    color: #058ED2;
    text-align: left;
    margin-bottom: 15px;
}
.hospital-left[data-v-d9c93b5d]{
    padding-right: 50px;
    width: 20%;
    text-align: left;
    border-right: 1px solid #C4C4C4;
}
.hospital-right[data-v-d9c93b5d] {
    padding-left: 50px;
    padding-right: 20px;
    width: 80%;
}
.hospital-list[data-v-d9c93b5d]:last-child {
    border: none;
}
.hs-detail[data-v-d9c93b5d] {
    text-align: left;
}
.star i[data-v-d9c93b5d] {
    color: #D6DADE;
    font-size: 14px;
    margin-right: 5px;
}
.star i.active[data-v-d9c93b5d] {
    color: #e6771b;
}
.hs-detail p[data-v-d9c93b5d] {
    margin-bottom: 0;
}
.star[data-v-d9c93b5d] {
    margin: 5px 0px;
}
.hospital-left ul li[data-v-d9c93b5d] {
    width: -webkit-max-content;
    width: max-content;
    position: relative;
    padding: 8px 0px;
}
.hospital-left ul li.active[data-v-d9c93b5d]:before {
    position: absolute;
    content: "";
    height: 4px;
    background: #E6771B;
    width: 100%;
    bottom: 3px;
}
/*Hospital search close*/





/*treatment section open*/
.Excellence[data-v-d9c93b5d] {
    background: #F1F3F5;
    position: relative;
    padding-left: 220px;
    min-height: 300px;
    padding-top: 25px;
    margin-top: 50px;
    text-align: left;
}
.Excellence img[data-v-d9c93b5d] {
    position: absolute;
    left: 25px;
    top: 25px;
    border: 20px solid #fff;
    border-radius: 100%;
    width: 180px;
    height: 180px;
    object-fit: cover;
}
.Excellence h2[data-v-d9c93b5d] {
    padding-bottom: 15px;
}
.Excellence ul li[data-v-d9c93b5d] {
    position: relative;
    line-height: 32px;
    padding-left: 25px;
    width: 50%;
    float: left;
}
.Excellence ul li[data-v-d9c93b5d]::before {
    width: 14px;
    height: 14px;
    content: "";
    background: #C4C4C4;
    position: absolute;
    border-radius: 100%;
    left: 0;
    top: 8px;
}
.right-sec[data-v-d9c93b5d] {
    padding-left: 60px;
}
.right-sec .form-contr[data-v-d9c93b5d] {
    background: #EDF2F7;
    color: #213B4D;
    margin-top: 50px;
    padding: 40px 30px; 
    padding-bottom: 20px;
}
.form-contr h2[data-v-d9c93b5d] {
    text-align: left;
    margin-bottom: 25px;
}
.right-sec label[data-v-d9c93b5d] {
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
}
.right-sec .form-contr .form[data-v-d9c93b5d] {
    height: 50px !important;
    width: 100% !important;
    border-radius: 5px !important;
    border: none !important;
    margin-bottom: 20px;
    padding: 0px 15px;
    background: #fff !important;
    color: #213B4D;
    position: relative;
    padding-left: 15px !important;
}
.right-sec .form-contr .mnt[data-v-d9c93b5d] {
    margin-top: 15px;
}
.right-sec .form-contr textarea[data-v-d9c93b5d] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    padding: 10px 15px;
}
.right-sec .recent-blog[data-v-d9c93b5d] {
    background: #EDF2F7;
    border-radius: 10px;
    padding: 40px 30px;
    margin-top: 30px;
}
.right-sec .recent-blog h2[data-v-d9c93b5d] {
    margin-bottom: 25px;
}
.right-sec .recent-blog .blog-list[data-v-d9c93b5d] {
    position: relative;
    padding-left: 88px;
    min-height: 90px;
    text-align: left;
}
.right-sec .recent-blog .blog-list img[data-v-d9c93b5d] {
    max-width: 68px;
    position: absolute;
    left: 0;
    top: 0;
}
.right-sec .recent-blog .blog-list h2[data-v-d9c93b5d] {
    font-size: 18px;
    margin-bottom: 8px;
}
.right-sec .recent-blog .blog-list .content[data-v-d9c93b5d] {
    font-size: 14px;
    line-height: 20px;
}
/*treatment section close*/
.ds-hide[data-v-d9c93b5d]{
    display: none;
}
@media all and (max-width: 1440px) {
.banner-bottom h1 .arrow-right[data-v-d9c93b5d] {
    width: 12px;
}
}
@media all and (max-width: 1199px) {
.sm-hide[data-v-d9c93b5d]{
        display: none;
}
.ds-hide[data-v-d9c93b5d]{
        display: block;
}
}
@media all and (max-width: 991px) {
}
@media all and (max-width: 767px) {
}
@media all and (max-width: 599px) {
.banner-bottom h1[data-v-d9c93b5d]{
        max-width: 90%;
        padding: 15px;
        text-align: center;
}
.banner-bottom h1 span[data-v-d9c93b5d]{
        display: inline-block;
        margin: 15px 0px;
        width: 100%;
}
.banner-bottom h1 img[data-v-d9c93b5d]{
        position: static;
        position: initial;
        -webkit-transform:none;
                transform:none;
        width: 40px;
}
img.arrow-right[data-v-d9c93b5d] {
        width: 15px !important;
}
}