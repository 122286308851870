




/*doctor detail section open*/
.doctor-detail[data-v-a7421c44] {
    margin-top: 80px;
    position: relative;
    padding: 20px;
    padding-left: 195px;
    background: #F1F3F5;
    border-radius: 10px;
    min-height: 250px;
}
.ask-now[data-v-a7421c44] {
    background: #058ED2;
    border-radius: 5px;
    color: #fff;
    width: 100px;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    margin-left: 20px;
}
.ask-now[data-v-a7421c44]:hover{
    background: #067eb9;
    color: #fff;
}
.doctor-im[data-v-a7421c44] {
    position: absolute;
    left: 20px;
    display: grid;
}
.verify[data-v-a7421c44] {
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 280px;
    padding: 10px;
}
.verify-left[data-v-a7421c44] {
    width: 90px;
    float: left;
    border-right: 1px solid #BCBEC0;
}
.verify-right[data-v-a7421c44] {
    width: 170px;
    float: left;
    padding-left: 10px;
    padding-top: 7px;
}
.star img[data-v-a7421c44] {
    width: 13px;
}
.enquire-now[data-v-a7421c44] {
    color: #213B4D;
    background: #F1F3F5;
    border-radius: 5px;
    font-size: 12px;
    width: 110px;
    float: left;
    text-align: center;
    height: 30px;
    line-height: 30px;
}
.enquire-now[data-v-a7421c44]:hover {
    color: #213B4D;
    background: #e3e5e7;
}
.verify p[data-v-a7421c44] {
    margin-bottom: 0;
}
.ds-detail p[data-v-a7421c44] {
    margin-bottom: 7px;
}
/*doctor detail section close*/

/*Core speciality open*/
.tl-contr[data-v-a7421c44] {
    margin-bottom: 30px;
    display: inline-block;
    margin-top: 50px;
}
.tl-contr h1[data-v-a7421c44] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-a7421c44]::before {
    content: "";
    background: #e6771b;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.mn-head[data-v-a7421c44]{
    border-bottom: 1px solid #E5E5E5;
    padding: 0;
    margin-top: 50px;
}
ul.mn-head li[data-v-a7421c44] {
    float: left;
    margin-right: 30px;
    position: relative;
    padding-bottom: 10px;
}
ul.mn-head li.active[data-v-a7421c44]:before {
    width: 55px;
    height: 5px;
    background: #E6771B;
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
}
ul.mn-head li.active[data-v-a7421c44]{
    color: #E6771B;
}
ul.core-contr li[data-v-a7421c44] {
    float: left;
    width: 33.333%;
    margin-bottom: 30px;
}
ul.core-contr li h2 img[data-v-a7421c44] {
    margin-right: 10px;
    margin-top: -5px;
}

/*Core speciality close*/
.right-sec[data-v-a7421c44] {
    padding-left: 60px;
}
.right-sec .form-contr[data-v-a7421c44] {
    background: #e6771b;
    color: #fff;
    margin-top: 80px;
    padding: 50px 30px;
}
.right-sec .form-contr .form[data-v-a7421c44] {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 0px 15px;
    background: #fff;
    color: #213b4d;
    position: relative;
}
.right-sec .form-contr .mnt[data-v-a7421c44] {
    margin-top: 15px;
}
.right-sec .form-contr textarea[data-v-a7421c44] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 10px 15px;
}
.right-sec .form-contr span[data-v-a7421c44] {
    line-height: 50px;
}
.right-sec .recent-blog[data-v-a7421c44] {
    margin-top: 80px;
}
.right-sec .recent-blog h2[data-v-a7421c44] {
    margin-bottom: 25px;
}
.right-sec .recent-blog .blog-list h2[data-v-a7421c44] {
    font-size: 18px;
    margin-bottom: 8px;
}
.right-sec .recent-blog .blog-list .content[data-v-a7421c44] {
    font-size: 14px;
    line-height: 20px;
}
.blog-list img[data-v-a7421c44] {
    border-radius: 10px;
    margin-bottom: 15px;
}
.adds[data-v-a7421c44]{
    margin-bottom: 20px;
}
.doctor-im img[data-v-a7421c44] {
    width: 150px;
    height: 150px;
    object-fit: cover;
    background: #fff;
}
.adds img[data-v-a7421c44] {
    width: 100%;
}
@media all and (max-width: 1600px) {
.main-pd[data-v-a7421c44] {
        padding: 0px 100px;
}
}
