.dis-section[data-v-e5a2c68a] {
    background: #F1F3F5;
    position: relative;
}
.dis-section a.read-more[data-v-e5a2c68a] {
    width: 208px;
    height: 53px;
    background: #E6771B;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 53px;
    position: absolute;
    right: 410px;
    margin-top: 30px;
}
.dis-section .content-sec[data-v-e5a2c68a] {
    padding: 60px 410px 60px 60px;
    min-height: 410px;
    margin-top: 50px;
}
.dis-section .content-sec h1[data-v-e5a2c68a] {
    margin-bottom: 20px;
}
.dis-section .dis-im[data-v-e5a2c68a] {
    position: absolute;
    right: 20px;
    top: 20px;
}
.dis-section .dis-im img[data-v-e5a2c68a] {
    max-width: 350px;
}
.tl-contr[data-v-e5a2c68a] {
    display: inline-block;
}
.tl-contr h1[data-v-e5a2c68a] {
    margin-bottom: 30px;
    position: relative;
    padding-top: 15px;
}
.tl-contr h1[data-v-e5a2c68a]::before {
    content: "";
    background: #E6771B;
    height: 2px;
    width: 80px;
    position: absolute;
    margin-bottom: 20px;
    top: 0;
}
.packages[data-v-e5a2c68a] {
    padding: 50px 20px;
    background: #F1F3F5;
    padding-bottom: 30px;
    margin: 0;
}
.packages .item-list[data-v-e5a2c68a] {
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
    position: relative;
}
.packages .item-list img[data-v-e5a2c68a] {
    width: 100%}
.packages .item-list h2[data-v-e5a2c68a] {
    text-align: left;
    padding: 20px;
    margin-bottom: 0;
}
.packages .item-list .benefit[data-v-e5a2c68a] {
    padding: 20px;
    background: #d0eaf7;
    position: relative;
}
.packages .item-list .benefit h2[data-v-e5a2c68a] {
    padding: 0;
    padding-bottom: 15px;
}
.packages .item-list .benefit ul[data-v-e5a2c68a] {
    margin: 0;
}
.packages .item-list .benefit ul li[data-v-e5a2c68a] {
    text-align: left;
    position: relative;
    padding-left: 30px;
    line-height: 28px;
}
.packages .item-list .benefit ul li[data-v-e5a2c68a]::before {
    background-image: url(../../images/star-icon.svg);
    background-size: contain;
    position: absolute;
    left: 0;
    content: "";
    width: 15px;
    height: 15px;
    top: 5px;
}
.packages .item-list .benefit .view[data-v-e5a2c68a] {
    background: #058ED2;
    width: auto;
    float: right;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-family: TitilliumWeb-SemiBold;
}
.packages .item-list .hospital[data-v-e5a2c68a] {
    width: 230px;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    z-index: 1;
    height: 208px;
    background: #fff;
    -webkit-clip-path: polygon(0 0,  100% 0,  29% 72%,  0 100%);
    clip-path: polygon(0 0,  100% 0,  29% 72%,  0 100%);
    padding-right: 110px;
    border-radius: 10px 0px;
}
.packages .item-list .hospital .c-one[data-v-e5a2c68a] {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 21px;
    font-family: TitilliumWeb-SemiBold;
}
.packages .item-list .hospital img[data-v-e5a2c68a] {
    width: 30px !important;
    margin-bottom: 10px;
}
.packages .item-list .botm-content[data-v-e5a2c68a] {
    padding: 20px;
    padding-bottom: 25px;
}
.packages .item-list .botm-content p[data-v-e5a2c68a] {
    text-align: left;
    float: left;
    width: 50%;
    font-size: 21px;
}
.packages .item-list .botm-content p span[data-v-e5a2c68a] {
    font-family: TitilliumWeb-SemiBold;
}
.packages .item-list .botm-content .color[data-v-e5a2c68a] {
    color: #E6771B;
}
.packages .item-list .botm-content .color span[data-v-e5a2c68a] {
    font-size: 16px;
    font-family: TitilliumWeb-Regular;
}
.packages .item-list .botm-content .book-now[data-v-e5a2c68a] {
    height: 50px;
    background: #E6771B;
    color: #fff;
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 24px;
    font-family: TitilliumWeb-SemiBold;
}
.testi-contr[data-v-e5a2c68a] {
    margin-top: 70px;
}
.testi-contr .testimonial[data-v-e5a2c68a] {
    background: #F1F3F5;
    padding: 40px;
}
.testi-contr .testimonial img[data-v-e5a2c68a] {
    max-width: 170px;
    margin: auto;
}
.testi-contr .testimonial h2[data-v-e5a2c68a] {
    margin-top: 15px;
}
.testi-contr .testimonial iframe[data-v-e5a2c68a] {
    border-radius: 20px;
}
.testi-contr .testimonial .pleft[data-v-e5a2c68a] {
    padding-right: 70px;
}
.testi-contr .testimonial .pright[data-v-e5a2c68a] {
    padding-left: 70px;
}
.testi-contr .testimonial .line[data-v-e5a2c68a] {
    position: absolute;
    background: #E6771B;
    height: 200px;
    width: 1px;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.cn-section[data-v-e5a2c68a] {
    background: #F1F3F5;
    padding: 50px;
    margin-top: 65px;
    font-size: 21px;
    line-height: 32px;
}
.best-doctor-contr[data-v-e5a2c68a] {
    padding-top: 100px;
    padding-bottom: 100px;
}
.best-doctor-contr img[data-v-e5a2c68a] {
    border: 20px solid #F1F3F5;
    border-radius: 100%;
    width: 180px;
    height: 180px;
    margin: auto;
    margin-bottom: 20px;
}
.best-doctor-contr h2.inline[data-v-e5a2c68a] {
    display: inline-flex;
    margin-top: 10px;
    font-size: 18px;
}
.best-doctor-contr h2.inline img[data-v-e5a2c68a] {
    border: none;
    border-radius: 0;
    margin-right: 10px;
    margin-bottom: 0;
    width: 20px !important;
    height: auto;
}
.best-doctor-contr h3[data-v-e5a2c68a] {
    color: #898890;
}
.best-hh[data-v-e5a2c68a] {
    padding-bottom: 0;
}
.best-hh img[data-v-e5a2c68a] {
    border: none;
    border-radius: 10px;
}
.best-hh h2.inline[data-v-e5a2c68a] {
    font-family: TitilliumWeb-Regular;
}
.best-hh h2.inline img[data-v-e5a2c68a] {
    margin-top: 0;
}
.plans-main-contr[data-v-e5a2c68a] {
    padding-top: 70px;
}
.plans-main-contr .plans-contr[data-v-e5a2c68a] {
    float: left;
    width: 100%}
.plans-main-contr .plans-contr .plan-list[data-v-e5a2c68a] {
    float: left;
    background: #EDF2F7;
    width: 24%;
    height: 235px;
    border-right: 2px solid #fff;
    transition: all 0.5s ease;
}
.plans-main-contr .plans-contr .plan-list[data-v-e5a2c68a]:last-child {
    border-right: 0;
    width: 28%}
.plans-main-contr .plans-contr .plan-list[data-v-e5a2c68a]:hover {
    background: #E6771B;
    color: #fff;
}
.plans-main-contr .plans-contr .plan-list:hover h1[data-v-e5a2c68a] {
    color: #fff !important;
}
.plans-main-contr .plans-contr .plan-list .list-plan[data-v-e5a2c68a] {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding: 0px 15px;
}
.plans-main-contr .plans-contr .plan-list .list-plan h1[data-v-e5a2c68a] {
    color: #E6771B;
    font-family: TitilliumWeb-Bold;
    font-size: 24px;
}
.plans-main-contr .plans-contr .plan-list .list-plan .cnnt[data-v-e5a2c68a] {
    font-size: 48px;
}
.plans-main-contr .plans-contr .plan-list .list-plan .cl-one[data-v-e5a2c68a] {
    border: 1px solid #213B4D;
    border-radius: 7px;
    background: #fff;
    padding: 8px 5px;
    color: #213B4D;
    display: inline-block;
    margin-top: 10px;
    width: 180px;
}
.right-sec[data-v-e5a2c68a] {
    padding-left: 60px;
}
.right-sec .form-contr[data-v-e5a2c68a] {
    background: #E6771B;
    color: #fff;
    margin-top: 50px;
    padding: 50px 30px;
}
.right-sec .form-check[data-v-e5a2c68a] {
    min-height: 2.5rem;
}
.right-sec .form-contr .form[data-v-e5a2c68a] {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 0px 15px;
    background: #fff;
    color: #213B4D;
    position: relative;
}
.right-sec .form-contr .mnt[data-v-e5a2c68a] {
    margin-top: 15px;
}
.right-sec .form-contr textarea[data-v-e5a2c68a] {
    height: 120px;
    width: 100%;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    padding: 10px 15px;
}
.right-sec .form-contr .inputfile[data-v-e5a2c68a] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.right-sec .form-contr span[data-v-e5a2c68a] {
    line-height: 50px;
}
.right-sec .form-contr .inputfile+label[data-v-e5a2c68a] {
    cursor: pointer;
}
.right-sec .form-contr .no-js .inputfile+label[data-v-e5a2c68a] {
    display: none;
}
.right-sec .form-contr .inputfile:focus+label[data-v-e5a2c68a], .right-sec .form-contr .inputfile.has-focus+label[data-v-e5a2c68a] {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}
.right-sec .form-contr .inputfile+label svg[data-v-e5a2c68a] {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    position: absolute;
    right: 15px;
    top: 15px;
}
.right-sec .recent-blog[data-v-e5a2c68a] {
    background: #EDF2F7;
    border-radius: 10px;
    padding: 40px 30px;
    margin-top: 80px;
}
.right-sec .recent-blog h2[data-v-e5a2c68a] {
    margin-bottom: 25px;
}
.right-sec .recent-blog .blog-list[data-v-e5a2c68a] {
    position: relative;
    padding-left: 88px;
    min-height: 90px;
}
.right-sec .recent-blog .blog-list img[data-v-e5a2c68a] {
    max-width: 68px;
    position: absolute;
    left: 0;
}
.right-sec .recent-blog .blog-list h2[data-v-e5a2c68a] {
    font-size: 18px;
    margin-bottom: 8px;
}
.right-sec .recent-blog .blog-list .content[data-v-e5a2c68a] {
    font-size: 14px;
    line-height: 20px;
}
@media all and (max-width: 1600px) {
.main-pd[data-v-e5a2c68a] {
    padding: 0px 100px;
}
}
.dis-section button.read-more[data-v-e5a2c68a] {
    width: 208px;
    height: 53px;
    background: #E6771B;
    border-radius: 10px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 53px;
    position: absolute;
    right: 410px;
    margin-top: 30px;
    border-color: transparent;
}